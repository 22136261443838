import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "../styles/scss/components/collage.module.scss"
import Imgix from "react-imgix"

class Collage extends Component {
    constructor(props) {
        super()
    }
    static defaultProps = {}

    render() {
        const { images } = this.props
        const {
            boxOne,
            boxTwo,
            boxThree,
            boxFour,
            boxFive,
            boxSix,
            boxSeven,
            boxEight,
            boxNine,
        } = images.desktop

        return (
            <div className={`${styles.collageMainWrapper}`}>
                <div className={`${styles.collageDesktopLayout}`}>
                    <div className={`${styles.asTwoHorizontalSplitAndOnlyOne}`}>
                        <div className={`${styles.asTwoHorizontalSplit}`}>
                            <Imgix src={boxOne.src} alt={boxOne.alt} htmlAttributes={{alt: boxOne.alt}} />
                            <Imgix src={boxTwo.src} alt={boxTwo.alt} htmlAttributes={{alt: boxOne.alt}} />
                        </div>
                        <div className={`${styles.AndOnlyOne}`}>
                            <Imgix src={boxThree.src} alt={boxThree.alt} htmlAttributes={{alt: boxThree.alt}} />
                        </div>
                    </div>
                    <div className={`${styles.asOneAndTwoAsVerticalSplit}`}>
                        <div className={`${styles.asOne}`}>
                            <Imgix src={boxFour.src} alt={boxFour.alt} htmlAttributes={{alt: boxFour.alt}} />
                        </div>
                        <div className={`${styles.TwoAsVerticalSplit}`}>
                            <Imgix src={boxFive.src} alt={boxFive.alt} htmlAttributes={{alt: boxFive.alt}} />
                            <Imgix src={boxSix.src} alt={boxSix.alt} htmlAttributes={{alt: boxSix.alt}} />
                        </div>
                    </div>
                    <div
                        className={`${styles.asOneAndTwoAsVerticalSplitReverse}`}
                    >
                        <div className={`${styles.asOneReverse}`}>
                            <Imgix src={boxNine.src} alt={boxNine.alt} htmlAttributes={{alt: boxNine.alt}} />
                        </div>
                        <div className={`${styles.TwoAsVerticalSplitReverse}`}>
                            <Imgix src={boxSeven.src} alt={boxSeven.alt} htmlAttributes={{alt: boxSeven.alt}} />
                            <Imgix src={boxEight.src} alt={boxEight.alt} htmlAttributes={{alt: boxEight.alt}} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Collage.propTypes = {
    images: PropTypes.object.isRequired,
}

export default Collage
