import React from "react"
import NewLayout from "../layout/newLayout"
import Title from "../components/title"
import SEO from "../components/seo"
import cn from "classnames"
import { supSub } from "../services/util"
import styles from "../styles/scss/templates/sema.module.scss"
import HeroSema from "../components/heroSema"
import CanBanner from "../components/canBanner"
import ImageBlock from "../components/imageBlock"
import CtaBanner from "../components/ctaBanner"
import Collage from "../components/collage"
import Partners from "../components/partners"
import GallerySema from "../components/gallerySema"

import { isMobile } from "react-device-detect"
import {
    collageData,
    partnersData,
    galleryData,
} from "../../static/img/sema-building/semaBuildingData"
import Imgix from "react-imgix"

const Sema2021 = ({ ...props }) => {
    const scrollFun = id => {
        return document
            .querySelector(`#${id}`)
            .scrollIntoView({ block: "center", behavior: "smooth" })
    }

    // Context/Data of the Page
    const pageData = props.pageContext.page
    // End of Context/Data

    const { heroDesktop, heroMobile, seo } = pageData
    const { title: partnersTitle, logos } = partnersData
    const pageTitle = seo.description

    // Data that comes from the CMS to consume divided in sections (rows), with console.log for development purposes for now.
    const firstSection = {
        title: pageData.content[0].subtitle,
        text: pageData.content[0].body,
    }
    const secondSection = {
        imageUrl: pageData.content[1].image.url,
        text: pageData.content[1].body,
    }
    const thirdSection = {
        imageUrl: pageData.content[2].image.url,
        text: pageData.content[2].body,
    }
    const fourthSection = pageData.content[3].body
    const fifthSection = {
        imageUrl: pageData.content[4].image.url,
        text: pageData.content[4].body,
    }
    const sixthSection = pageData.content[5].image.url
    const seventhSection = {
        imageUrl: pageData.content[6].image.url,
        text: pageData.content[6].body,
    }

    return (
        <NewLayout>
            <SEO page={pageData} />

            <div className={cn(styles.semaWrapper, styles.fadeIn)}>
                {heroDesktop ? (
                    <HeroSema
                        images={[heroMobile, heroDesktop]}
                        title={pageTitle}
                        onClick={() => scrollFun("gallery")}
                        goTo={"gallery"}
                    />
                ) : (
                    false
                )}
                {/* First Text */}
                <div className={cn(styles.firstSection)}>
                    <Title
                        className={styles.firstSection_title}
                        value={firstSection.title}
                    />
                    <div
                        className={cn(styles.firstSection_text)}
                        dangerouslySetInnerHTML={{
                            __html: supSub(firstSection.text),
                        }}
                    />
                </div>

                {/* Text with Blue Box*/}
                <div className={cn(styles.secondSection)}>
                    <ImageBlock
                        image={secondSection.imageUrl}
                        containerClass={cn(styles.secondSection_imageContainer)}
                        imageClass={
                            styles.secondSection_imageContainer_imageClass
                        }
                    />
                    <div
                        className={cn(styles.secondSection_text)}
                        dangerouslySetInnerHTML={{
                            __html: supSub(secondSection.text),
                        }}
                    />
                </div>

                {/* Text with yellow box */}
                <div className={cn(styles.thirdSection)}>
                    <div
                        className={cn(styles.thirdSection_text)}
                        dangerouslySetInnerHTML={{
                            __html: supSub(thirdSection.text),
                        }}
                    />

                    <ImageBlock
                        image={thirdSection.imageUrl}
                        containerClass={cn(styles.thirdSection_imageContainer)}
                        imageClass={
                            styles.thirdSection_imageContainer_imageClass
                        }
                    />
                </div>

                {/* Text alone*/}
                <div className={cn(styles.fourthSection)}>
                    <div
                        className={cn(styles.fourthSection_text)}
                        dangerouslySetInnerHTML={{
                            __html: supSub(fourthSection),
                        }}
                    />
                </div>

                {/* First Image Collage*/}

                <Collage images={collageData} />

                {/* Banner */}
                <div className={cn(styles.fifthSection)}>
                    <CanBanner
                        text={fifthSection.text}
                        image={fifthSection.imageUrl}
                    />
                </div>

                <div className={cn(styles.sixthSection)}>
                    <div>
                        <Imgix
                            className={cn(
                                styles.sixthSection_imageContainer,
                                styles.sixthSection_imageContainer_imageClass
                            )}
                            src={sixthSection}
                            htmlAttributes={{ alt: '' }}
                        />
                    </div>
                </div>

                <div className={cn(styles.seventhSection)}>
                    <div className={styles.seventhSection_imageContainer}>
                        <Imgix
                            src={seventhSection.imageUrl}
                            className={
                                styles.seventhSection_imageContainer_imageClass
                            }
                            htmlAttributes={{ alt: '' }}
                        />
                    </div>
                    <div
                        className={cn(styles.seventhSection_text)}
                        dangerouslySetInnerHTML={{
                            __html: supSub(seventhSection.text),
                        }}
                    />
                </div>
                {partnersData ? (
                    <Partners
                        title={partnersTitle}
                        logos={logos}
                        isMobile={isMobile}
                    />
                ) : (
                    false
                )}
                <div id="gallery">
                    {galleryData && (
                        <GallerySema
                            data={galleryData}
                            isMobile={isMobile}
                            ctaText={"VIEW THE GALLERY"}
                        />
                    )}
                </div>
            </div>

            <CtaBanner
                text={`SUBSCRIBE TO WD-40 EMAIL UPDATES`}
                buttonText="Subscribe"
                buttonUrl="/signup"
            />
        </NewLayout>
    )
}

export default Sema2021
