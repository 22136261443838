import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "../styles/scss/components/heroSema.module.scss"
import { buildURL } from "react-imgix"
import Title from "./title"

class HeroSema extends Component {
    constructor(props) {
        super()
    }
    static defaultProps = {}

    render() {
        const { images, title, onClick } = this.props

        const getTitleParts = (title, part) => {
            return title.split("\n")[part]
        }

        const divStyles = {
            mobile: {
                background: "url(" + buildURL(images[0].url, {}) + ")",
            },
            desktop: {
                background: "url(" + buildURL(images[1].url, {}) + ")",
            },
        }
        return (
            <div className={styles.heroDesktop} style={divStyles.desktop}>
                <div className={styles.heroMobile} style={divStyles.mobile}>
                    <div className={styles.heroContent}>
                        <Title className={styles.heroSemaTitle}>
                            {getTitleParts(title, 0)}
                        </Title>
                        <Title className={styles.heroSemaSubTitle}>
                            {getTitleParts(title, 1)}
                        </Title>
                        <div
                            aria-hidden
                            role="button"
                            onKeyDown={onClick}
                            className={styles.heroSemaButtonContainer}
                            onClick={onClick}
                        >
                            <p className={styles.redButton}>VIEW THE GALLERY</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

HeroSema.propTypes = {
    images: PropTypes.array.isRequired,
    title: PropTypes.string,
    goTo: PropTypes.string,
}

export default HeroSema
