// Carousel Product Images
export const collageData = {
  mobile: {
    boxOne: { id: 1, src: '/img/sema-building/semaCollage/collage1-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 1' },
    boxTwo: { id: 2, src: '/img/sema-building/semaCollage/collage2-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 2' },
    boxThree: { id: 3, src: '/img/sema-building/semaCollage/collage3-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 3' },
    boxFour: { id: 4, src: '/img/sema-building/semaCollage/collage4-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 4' },
    boxFive: { id: 5, src: '/img/sema-building/semaCollage/collage5-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 5' },
    boxSix: { id: 6, src: '/img/sema-building/semaCollage/collage6-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 6' },
    boxSeven: { id: 7, src: '/img/sema-building/semaCollage/collage7-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 7' },
    boxEight: { id: 8, src: '/img/sema-building/semaCollage/collage8-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 8' },
    boxNine: { id: 9, src: '/img/sema-building/semaCollage/collage9-mobile.png', alt: 'WD-40® Brand & T.R.A.D. Collage 9' },
  }
  ,
  desktop: {
    boxOne: { id: 1, src: '/img/sema-building/semaCollage/collage1.png', alt: 'WD-40® Brand & T.R.A.D. Collage 1' },
    boxTwo: { id: 2, src: '/img/sema-building/semaCollage/collage2.png', alt: 'WD-40® Brand & T.R.A.D. Collage 2' },
    boxThree: { id: 3, src: '/img/sema-building/semaCollage/collage3.png', alt: 'WD-40® Brand & T.R.A.D. Collage 3' },
    boxFour: { id: 4, src: '/img/sema-building/semaCollage/collage4.png', alt: 'WD-40® Brand & T.R.A.D. Collage 4' },
    boxFive: { id: 5, src: '/img/sema-building/semaCollage/collage5.png', alt: 'WD-40® Brand & T.R.A.D. Collage 5' },
    boxSix: { id: 6, src: '/img/sema-building/semaCollage/collage6.png', alt: 'WD-40® Brand & T.R.A.D. Collage 6' },
    boxSeven: { id: 7, src: '/img/sema-building/semaCollage/collage7.png', alt: 'WD-40® Brand & T.R.A.D. Collage 7' },
    boxEight: { id: 8, src: '/img/sema-building/semaCollage/collage8.png', alt: 'WD-40® Brand & T.R.A.D. Collage 8' },
    boxNine: { id: 9, src: '/img/sema-building/semaCollage/collage9.png', alt: 'WD-40® Brand & T.R.A.D. Collage 9' },
  },
}
export const partnersData = {
  title: 'Partners who made it possible',
  logos: [
    { id: 1, src: '/img/sema-building/partners/SEMA Logos3.png', alt: 'WD-40® MockStock' },
    { id: 2, src: '/img/sema-building/partners/SEMA Logos4.png', alt: 'WD-40® Currie' },
    { id: 3, src: '/img/sema-building/partners/SEMA Logos2.png', alt: 'WD-40® AirHead Designs' },
    { id: 4, src: '/img/sema-building/partners/SEMA Logos8.png', alt: 'WD-40® ToyoTires' },
    { id: 5, src: '/img/sema-building/partners/SEMA Logos7.png', alt: 'WD-40® Dakota Digital' },
    { id: 6, src: '/img/sema-building/partners/SEMA Logos6.png', alt: 'WD-40® Ultra Wheel Company' },
    { id: 7, src: '/img/sema-building/partners/SEMA Logos13.png', alt: 'WD-40® Holley Sniper EFI' },
    { id: 8, src: '/img/sema-building/partners/SEMA Logos5.png', alt: 'WD-40® Dynamic Motosports' },
    { id: 9, src: '/img/sema-building/partners/SEMA Logos10.png', alt: 'WD-40® MTX Audio' },
    { id: 10, src: '/img/sema-building/partners/SEMA Logos9.png', alt: 'WD-40® Reel Driveline' },
    { id: 11, src: '/img/sema-building/partners/SEMA Logos1.png', alt: 'WD-40® Magnaflow' },
    { id: 12, src: '/img/sema-building/partners/SEMA Logos12.png', alt: 'WD-40® DeBons Performance' },
    { id: 13, src: '/img/sema-building/partners/SEMA Logos14.png', alt: 'WD-40® Holley' },
    { id: 14, src: '/img/sema-building/partners/SEMA Logos15.png', alt: 'WD-40® Frostbite Performance Cooling' },
  ]
};
export const partnersData2024 = {
  title: 'Partners who made it possible',
  logos: [
    { id: 1, src: '/img/sema-building/partners2024/SEMA Logos1.png', alt: 'WD-40® MockStock' },
    { id: 2, src: '/img/sema-building/partners2024/SEMA Logos2.png', alt: 'WD-40® Currie' },
    { id: 3, src: '/img/sema-building/partners2024/SEMA Logos3.png', alt: 'WD-40® AirHead Designs' },
    { id: 4, src: '/img/sema-building/partners2024/SEMA Logos4.png', alt: 'WD-40® ToyoTires' },
    { id: 5, src: '/img/sema-building/partners2024/SEMA Logos5.png', alt: 'WD-40® Dakota Digital' },
    { id: 6, src: '/img/sema-building/partners2024/SEMA Logos6.png', alt: 'WD-40® Ultra Wheel Company' },
    { id: 7, src: '/img/sema-building/partners2024/SEMA Logos7.png', alt: 'WD-40® Holley Sniper EFI' },
    { id: 8, src: '/img/sema-building/partners2024/SEMA Logos8.png', alt: 'WD-40® Dynamic Motosports' },
    { id: 9, src: '/img/sema-building/partners2024/SEMA Logos9.png', alt: 'WD-40® MTX Audio' },
    { id: 10, src: '/img/sema-building/partners2024/SEMA Logos10.png', alt: 'WD-40® Reel Driveline' },
    { id: 11, src: '/img/sema-building/partners2024/SEMA Logos11.png', alt: 'WD-40® Magnaflow' },
    { id: 12, src: '/img/sema-building/partners2024/SEMA Logos12.png', alt: 'WD-40® DeBons Performance' },
    { id: 13, src: '/img/sema-building/partners2024/SEMA Logos13.png', alt: 'WD-40® Holley' },
    { id: 14, src: '/img/sema-building/partners2024/SEMA Logos14.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 15, src: '/img/sema-building/partners2024/SEMA Logos15.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 16, src: '/img/sema-building/partners2024/SEMA Logos16.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 17, src: '/img/sema-building/partners2024/SEMA Logos17.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 18, src: '/img/sema-building/partners2024/SEMA Logos18.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 19, src: '/img/sema-building/partners2024/SEMA Logos19.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 20, src: '/img/sema-building/partners2024/SEMA Logos20.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 21, src: '/img/sema-building/partners2024/SEMA Logos21.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 22, src: '/img/sema-building/partners2024/SEMA Logos22.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 23, src: '/img/sema-building/partners2024/SEMA Logos23.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 24, src: '/img/sema-building/partners2024/SEMA Logos24.png', alt: 'WD-40® Frostbite Performance Cooling' },
    { id: 25, src: '/img/sema-building/partners2024/SEMA Logos25.png', alt: 'WD-40® Frostbite Performance Cooling' },
  ]
};
export const galleryData = {
  title: 'View the Gallery',
  src: '/img/sema-building/gallery/box',
  minSrc: '/img/sema-building/gallery-mini/box',
  alt: 'WD-40® Brand & T.R.A.D. gallery',
  images: 125,
  imagesOverview: 24,
};

export const galleryData2024 = {
  title: 'View the Gallery',
  src: '/img/sema-building/gallery2024/box',
  minSrc: '/img/sema-building/gallery2024-mini/box',
  alt: 'WD-40® Brand & T.R.A.D. gallery',
  images: 59,
  imagesOverview: 24,
};