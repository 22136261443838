import React, { useState } from "react"
import Title from "../components/title"
import Button from "../components/button"
import styles from "../styles/scss/components/gallery.module.scss"
import GalleryModal from "./galleryModal"
import Imgix from "react-imgix"

const GallerySema = ({ data, ctaText, id }) => {
    // STATES
    const [isModalOpen, setIsModalOpen] = useState(false)
    
    const [currentIndex, setCurrentIndex] = useState(0)

    // PROPS
    const { title, src, alt, images, imagesOverview, minSrc } = data

    // DEFAULTS
    const slides = [...Array(images).keys()]
    const sliderOverview = [...Array(imagesOverview).keys()]

    // HANDLERS
    const handlerOpenGallerySlick = index => {
        setCurrentIndex(index)
        setIsModalOpen(true)
    }

    const handlerGalleryImageSource = (src, index, format) => {
        return `${src}${index + 1}${format}`
    }

    const slideImages = slides.map((item, index) => {
        return { url: handlerGalleryImageSource(src, index, ".jpg") }
    })

    return (
        <div className={`${styles.galleryMainWrapper}`} id={id}>
            <div className={`${styles.galleryContentWrapper} `}>
                {
                    <>
                        <Title className={`${styles.galleryMainWrapper_title}`}>
                            {title}
                        </Title>
                        <div className={`${styles.galleryMainWrapper_images}`}>
                            {sliderOverview.map((item, index) => (
                                <>
                                    <div
                                        
                                        className={`${styles.galleryMainWrapper_images_ctaImage}`}
                                        aria-hidden
                                        role="button"
                                        onKeyDown={e => {
                                            e.preventDefault()
                                            handlerOpenGallerySlick(index)
                                        }}
                                        onClick={e => {
                                            e.preventDefault()
                                            handlerOpenGallerySlick(index)
                                        }}
                                    >
                                        <Imgix
                                            className={`${styles.galleryMainWrapper_images_item}`}
                                            key={index}
                                            src={handlerGalleryImageSource(
                                                minSrc,
                                                index,
                                                ".jpg"
                                            )}
                                            alt={`${alt}${index}`}
                                            htmlAttributes={{alt: `${alt}${index}`}}
                                        />
                                    </div>
                                </>
                            ))}
                        </div>
                        <Button
                            className={styles.redButton}
                            text={ctaText}
                            onClick={e => {
                                e.preventDefault()
                                handlerOpenGallerySlick(1)
                            }}
                        />
                    </>
                }

                {isModalOpen && (
                    <GalleryModal
                        images={slideImages}
                        isModalOpen={isModalOpen}
                        setCurrentIndex={setCurrentIndex}
                        currentIndex={currentIndex}
                        setIsModalOpen={setIsModalOpen}
                    />
                )}
            </div>
        </div>
    )
}

export default GallerySema
