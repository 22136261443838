import React, { Component } from "react"
import PropTypes from "prop-types"
import styles from "../styles/scss/components/canBanner.module.scss"
import { supSub } from "../services/util"
import Imgix from "react-imgix"

export default class canBanner extends Component {
    constructor(props) {
        super()
    }
    static defaultProps = {}

    render() {
        const { text, image } = this.props

        return (
            <div className={styles.canBanner}>
                <div className={styles.canBannerContent}>
                    <div className={styles.imageContainer}>
                        <Imgix
                            htmlAttributes={{alt: 'Banner Promotional'}}
                            src={image}
                            className={styles.imageClass}
                        />
                    </div>
                    <div className={styles.quotedTextContainer}>
                        <div
                            className={styles.textContainer}
                            dangerouslySetInnerHTML={{ __html: supSub(text) }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

canBanner.propTypes = {
    text: PropTypes.any.isRequired,
    imageUrl: PropTypes.string.isRequired,
}
